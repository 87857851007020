.aws_tables {
  tbody tr td:first-child {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    word-break: break-all;
  }
}


.img_table {
  border: none;
  tr td {
    vertical-align: bottom;
  }
}

